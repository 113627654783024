import type { FieldData } from 'rc-field-form/es/interface'

import { authorizationEndpoints } from '@redux/features/authorization/authorization.api'
import { setIsLoadingAuth } from '@redux/features/authorization/authorization.slice'
import type { PostCheckEmailType } from '@redux/features/authorization/types/checkEmail'
import type { LoginType, PostLoginType } from '@redux/features/authorization/types/login'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import authStorage from '@storageServices/storageEntities/auth'
import type { LoginFormType } from '@utils/forms/authorization/login'

import checkEmail from '../checkEmail'
import login from '../login'

export default createAsyncThunk<
  void,
  {
    loginForm: LoginFormType
    postCheckEmail: PostCheckEmailType
    postLogin: PostLoginType
    setErrors: (fields: FieldData[]) => void
  },
  { state: RootState }
>('authorization/handlerLogin', async (data, { dispatch, getState }) => {
  const { isCheckedEmail } = getState().authorization
  const isAuthToken = authStorage.getValueByKey('X-Auth-Token')

  if (isAuthToken) {
    void dispatch(setIsLoadingAuth(true))
    await dispatch(authorizationEndpoints.postLogout.initiate()).finally(() => {
      void dispatch(checkEmail(data))
      void dispatch(setIsLoadingAuth(false))
    })
  }

  if (isCheckedEmail && !isAuthToken) {
    void dispatch(login({ ...data, loginForm: { name: data.loginForm.email, pass: data.loginForm.pass } as LoginType }))
  } else if (!isAuthToken) {
    void dispatch(checkEmail(data))
  }
})
