import { ThemeNames } from '@themes/themes'

const defaultParameters = {
  countryCode: 'US',
  langcode: 'en',
  preferredCountries: ['US', 'DB', 'AU', 'FR', 'ES', 'KR'], //TODO from backend
  theme: ThemeNames.LIGHT,
}

export default defaultParameters
