export const resetAccountFormId = 'reset-password-form'

export enum AccountResetType {
  LoginCode = 'login_code',
  LoginEmail = 'login_email',
  PasswordReset = 'password_reset',
}
export enum ResetAccountFieldsName {
  Code = 'code',
  Destination = 'destination',
  Email = 'email',
  ResetType = 'reset_type',
}

export const resetPasswordPlaceHolders: { [ResetAccountFieldsName.Email]: string } = {
  [ResetAccountFieldsName.Email]: 'authorization.emailAddress',
}

export type AccountPasswordFormType = Partial<{
  [ResetAccountFieldsName.Code]: number
  [ResetAccountFieldsName.Destination]?: string
  [ResetAccountFieldsName.Email]: string
  [ResetAccountFieldsName.ResetType]: AccountResetType
}>
