export enum AuthorizationStep {
  CreatePassword = 'CreatePassword',
  Login = 'Login',
  LoginByCode = 'LoginByCode',
  LoginByCodeExtended = 'LoginByCodeExtended',
  LoginExtended = 'LoginExtended',
  Notification = 'Notification',
  Registration = 'Registration',
  RegistrationCheckEmail = 'RegistrationCheckEmail',
  RegistrationCheckEmailExtended = 'RegistrationCheckEmailExtended',
  ResetPassword = 'ResetPassword',
  SupportForm = 'SupportForm',
}
