import type { FieldData } from 'rc-field-form/es/interface'

import {
  saveEmail,
  setCurrentStep,
  setIsOpenAuthorizationPopup,
} from '@redux/features/authorization/authorization.slice'
import type { LoginByCodeType, UserType } from '@redux/features/authorization/types/login'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TypedMutationTrigger } from '@reduxjs/toolkit/dist/query/react'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryMeta } from '@reduxjs/toolkit/query'

import type { ApiResponseErrorType } from '@Types/api/responseError'
import type { ResponseErrorType } from '@Types/common/responseError'
import { AuthorizationStep } from '@components/authorization/constant/steps'
import { LoginByCodeFieldsName } from '@utils/forms/authorization/loginByCode'

export type PostLoginByCodeType = TypedMutationTrigger<
  UserType,
  LoginByCodeType,
  BaseQueryFn<FetchArgs | string, unknown, ApiResponseErrorType, NonNullable<unknown>, FetchBaseQueryMeta>
>

export default createAsyncThunk<
  void,
  { loginForm: LoginByCodeType; postLoginByCode: PostLoginByCodeType; setErrors: (fields: FieldData[]) => void },
  { state: RootState }
>('authorization/loginByCode', ({ loginForm, postLoginByCode, setErrors }, { dispatch }) => {
  postLoginByCode(loginForm)
    .unwrap()
    .then(() => {
      dispatch(setIsOpenAuthorizationPopup(false))
      dispatch(setCurrentStep(AuthorizationStep.Login))
      dispatch(saveEmail(''))
    })
    .catch((error: ResponseErrorType) => {
      setErrors([{ errors: [error.data.message], name: LoginByCodeFieldsName.Code }])
    })
})
