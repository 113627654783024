import type { AuthorizationType } from '@redux/features/authorization/types/authorization'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { AuthorizationStep } from '@components/authorization/constant/steps'

import { authorizationEndpoints } from './authorization.api'

const initialState: AuthorizationType = {
  currentStep: AuthorizationStep.Login,
  email: '',
  isCheckedEmail: false,
  isLoadingAuth: false,
  isOpenAuthorizationPopup: false,
}

export const authenticationSlice = createSlice({
  extraReducers: builder =>
    builder
      .addMatcher(
        authorizationEndpoints.postRegistration.matchFulfilled,
        (state, { payload: { login_data, success } }) => {
          if (success) state.user = login_data
        }
      )
      .addMatcher(
        isAnyOf(authorizationEndpoints.postLogin.matchFulfilled, authorizationEndpoints.postLoginByCode.matchFulfilled),
        (state, { payload }) => {
          state.user = payload
        }
      )
      .addMatcher(authorizationEndpoints.postLogout.matchPending, state => {
        state.user = undefined
      }),

  initialState,

  name: 'authorization',

  reducers: {
    saveEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    setCurrentStep: (state, action: PayloadAction<AuthorizationStep>) => {
      state.currentStep = action.payload
    },
    setExcludePrivacy: (state, action: PayloadAction<boolean>) => {
      state.isExcludePrivacy = action.payload
    },
    setIsCheckedEmail: (state, action: PayloadAction<boolean>) => {
      state.isCheckedEmail = action.payload
    },
    setIsLoadingAuth: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAuth = action.payload
    },
    setIsOpenAuthorizationPopup: (state, action: PayloadAction<boolean>) => {
      state.isOpenAuthorizationPopup = action.payload
    },
    setMessageLogin: (state, action: PayloadAction<string>) => {
      state.message = action.payload
    },
  },
})

export const {
  saveEmail,
  setCurrentStep,
  setExcludePrivacy,
  setIsCheckedEmail,
  setIsLoadingAuth,
  setIsOpenAuthorizationPopup,
  setMessageLogin,
} = authenticationSlice.actions

export default authenticationSlice.reducer
