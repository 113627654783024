import { useEffect } from 'react'

import { setPageInteraction } from '@redux/features/commonApp/commonApp.slice'
import { useGetCurrentUserInfoQuery } from '@redux/features/user/api'
import { useAppDispatch } from '@redux/hooks'

export default () => {
  const { data: userInfo } = useGetCurrentUserInfoQuery()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const value = !!userInfo
    value && dispatch(setPageInteraction(value))
  }, [userInfo, dispatch])
}
