import { ApplicationNames } from '@constants/applications/applicationNames'

export const fontFamily: Record<ApplicationNames, string> = {
  [ApplicationNames.HST]: 'DM Sans, sans-serif',
  [ApplicationNames.RN_CLASSIC]: 'Arial, sans-serif',
  [ApplicationNames.RN_INTERIM]: 'Arial, sans-serif',
  [ApplicationNames.RN_MODERN]: 'Arial, sans-serif',
  [ApplicationNames.RN_NEW]: 'DM Sans, sans-serif',
  [ApplicationNames.SEARCH_WIDGET]: 'Arial',
}
