import { authorizationEndpoints } from '@redux/features/authorization/authorization.api'
import { userApi } from '@redux/features/user/api'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

export default createAsyncThunk<void, void, { state: RootState }>('authorization/logout', (_, { dispatch }) => {
  void dispatch(authorizationEndpoints.postLogout.initiate()).finally(() => {
    // @ts-ignore
    void dispatch(userApi.util.upsertQueryData(userApi.endpoints.getCurrentUserInfo.name, undefined, undefined))
  })
})
