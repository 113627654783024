export const loginFormId = 'login-form'

export enum LoginFieldsName {
  Email = 'email',
  Name = 'name',
  Password = 'pass',
}

export const loginFieldsPlaceHolders: { [LoginFieldsName.Email]: string; [LoginFieldsName.Password]: string } = {
  [LoginFieldsName.Email]: 'authorization.yourEmail',
  [LoginFieldsName.Password]: 'authorization.password',
}

export type LoginFormType = Partial<{
  [LoginFieldsName.Email]: string
  [LoginFieldsName.Name]: string
  [LoginFieldsName.Password]: string
}>
